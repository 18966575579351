
.bodycontent {
    width: 100%;
    height: 80vh;
    text-align: center;
    margin: 0;
    position:relative;
    z-index:0;
}

.bodycontent::before {
    content:"";
    position:absolute;
    z-index:-1;
    top:0;
    left:0;
    right:0;
    bottom:0;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    background-color: rgba(55, 97, 136, 0.4);
  }

.logo_intro {
    width: 20%;
    max-width: 480vh;
    min-height: 10vw;
    overflow: hidden;
    position: relative;
    margin: auto;
    padding: 1rem;
    margin-bottom: 3%;
    margin-top: 2%;
    }
  
  .logo_intro img {
    width: 100%;
    margin: auto;
    
    height: auto;
    position: relative;
    display: block;
    border: 3px solid #1a5f90;
    }

.textcontent {
    width: 70%;
    margin: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    z-index: 10;
}

.textcontent h1 {
    margin-bottom: 3%;
}

.textcontent p {
    margin-bottom: 5%;
}

.register {
    display: block;
    background-color: #1f4e79;
    color: white;
    padding: 1.5% 4%;
    font-size: 1.25rem;
    font-weight: bold;
    text-decoration: none;
    border: 2px solid #002060;
    margin-left: 1%;
  }
  
  .register:hover {
    filter: invert(0.1);
  }


.landing {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.footer {
    background-color: #F2F2F2;
    text-align: center;
    padding: 1rem;
    margin-top: auto;
  }



@media screen and (max-width: 40rem) {
   
    
    .landing p, .landing li {
      font-size: 1rem
    }
    .landing h1 {
      font-size: 1.5rem;
      margin: 0;
      padding: 0
    }
    .orgs {
      flex-direction: column;
      gap: 2.5rem;
    }
  
    .logo_container {
      flex-direction: column;
      gap: 1.5rem;
    }
  }