.tab,
.consumerTab {
    border: 1px solid #ddd;
    padding: 40px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    
}

.divisionLine {
    border-bottom: 1px solid #ccc; /* Adiciona uma borda inferior */
    padding-bottom: 20px; /* Espaçamento entre os suppliers */
}

.buttonsSupplier {
    text-align: center;
}

.removeInfoButton,
.removeMatSupplierButton,
.removeInventoryButton,
.removeSupplierButton,
.removeProcessButton {
    margin: 0;
    padding: 3px 8px;
    border: 2px solid rgb(250, 90, 90); 
    background-color: rgb(250, 90, 90); 
    color: #ffffff; /* White text */
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;  
}
.addMatSupplierButton,
.addInventoryButton,
.addProcessButton,
.addSupplierButton,
.addInfoButton{
    margin: 0;
    padding: 3px 8px;
    border: 2px solid rgb(100, 100, 100); /* Grey border */
    background-color: rgb(100, 100, 100); /* Grey background */
    color: #ffffff; /* White text */
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;  
}
.removeInfoButton:hover,
.removeMatSupplierButton:hover,
.removeInventoryButton:hover,
.removeSupplierButton:hover,
.removeProcessButton:hover{
    border: 2px solid rgb(250, 0, 0); 
    background-color: rgb(255, 0, 0);
}

.addMatSupplierButton:hover,
.addInventoryButton:hover,
.addProcessButton:hover,
.addSupplierButton:hover,
.addInfoButton:hover{
    background-color: #333333; /* Darker grey background on hover */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Remove outline on focus */
    border-color: #000000;
}

.removeInfoButton:focus,
.removeMatSupplierButton:focus,
.removeInventoryButton:focus,
.removeSupplierButton:focus,
.removeProcessButton:focus{
    background-color: rgb(255, 0, 0);;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Remove outline on focus */
    border-color: #ff0000;
}

.addSupplierButton:focus,
.addInventoryButton:focus
.addProcessButton:focus,
.addSupplierButton:focus,
.addInfoButton:focus{
    background-color: #333333;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Remove outline on focus */
    border-color: #000000;
}


.flex-container {
    position: absolute;
    top: 170px;
    right: 80px;
    margin: 10px;
  }

.flex-container button,
.previousButton button {
    margin-left: 200px; /* Adjust the margin as needed */
    display: inline-block;
    width: 100px; /* Defina a mesma largura para todos os botões */
    height: 30px; /* Defina uma altura fixa para o botão */
    border: 2px solid rgb(0, 99, 228); 
    background-color: rgb(0, 99, 228); 
    color: #ffffff; /* White text */
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}


.previousButton {
    position: absolute;
    top: 170px;
    left: -80px;
    margin: 10px;
  }

.flex-container button:hover,
.previousButton button:hover {
    background-color: #004d9e; /* Darker blue background on hover */
    border-color: #004d9e; /* Darker blue border on hover */
    color: #ffffff; /* White text on hover */
  }
  
  .flex-container button:focus,
  .previousButton button:focus {
    outline: none; /* Remove outline on focus */
  }

.tab h2,
.consumerTab h2 {
    display: inline; /* Keeps the h2 on the same line */
    font-size: 24px; /* Slightly increased font size for headings */
    margin-bottom: 15px;
    color: #333;
 /* Uppercase headings for a more refined look */
}

label {
    display: block;
    margin-bottom: 10px; /* Slightly reduced margin for labels */
    font-weight: bold;
    color: #555; /* Adjusted label color for better contrast */
}

input[type="text"] {
    width: calc(100% - 22px); /* Adjusted width to accommodate padding and border */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 15px; /* Increased margin bottom for input elements */
    color: #333; /* Defined input text color */
}

/* Additional styles for better readability and aesthetics */
.tab, .consumerTab {
    transition: box-shadow 0.3s ease; /* Smooth transition for box-shadow on hover */
}

.tab:hover, .consumerTab:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Slightly increased box-shadow on hover for depth */
}


.tab h3{
  font-size: 18px; /* Slightly increased font size for headings */
  margin-bottom: 5px;
  color: #333;
}
.infoFlow
.matSup,
.process,
.supplier,
.inventory {
    margin-bottom: 20px; /* Adiciona um espaço entre os grupos de campos */
}

.infoFlow:not(:first-child),
.matSup:not(:first-child),
.inventory:not(:first-child),
.process:not(:first-child),
.supplier:not(:first-child) {
    border-top: 1px solid #ccc; /* Adiciona uma linha divisória cinza acima de todos os grupos de campos, exceto o primeiro */
    padding-top: 20px; /* Adiciona um espaço acima da linha divisória para separá-la dos campos acima */
}
.data-review-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.data-review-container h1 {
  color: #333;
  margin-bottom: 20px;
}

.form-data-section,
.customer-form-data-section,
.suppliers-section,
.processes-section,
.inventories-section,
.material-flow-section,
.production-section {
  margin-bottom: 30px;
}

.project,
.customer,
.customer-mat,
.supplier-item,
.process-item,
.inventory-item,
.supplier-material-item,
.customer-productions,
.supplier-productions,
.process-productions {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.project p,
.customer p,
.customer-mat p,
.supplier-item p,
.process-item p,
.inventory-item p,
.supplier-material-item p,
.customer-productions p,
.supplier-productions p,
.process-productions p {
  margin: 5px 0;
}

.project h3,
.customer h3,
.customer-mat h3,
.supplier-item h3,
.process-item h3,
.inventory-item h3,
.supplier-material-item h3,
.customer-productions h3,
.supplier-productions h3,
.process-productions h3 {
  color: #3366cc;
  margin-bottom: 10px;
}

.submit-button{
    background-color: #3366cc;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    z-index: 1;
}

.submit-button:hover{
    background-color: #254785;
}

.editButtons{
  background-color: #ff0000;
  color: #fff;
  padding: 5px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  font-weight: bold;
}

.previous-button {
  background-color: #ff0000;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
  z-index: 1;
}

.previous-button:hover,
.editButtons:hover{
  background-color: #c11818;
}



/* Cabeçalho da caixa */
.process-field-vertical h2 {
  font-weight: bold; /* Texto em negrito */
  text-align: center;
  margin-bottom: 20px; /* Espaçamento inferior */
}

/* Etiquetas e inputs */
.process-field-vertical label {
  float: right;
  display: block; /* Exibe cada label em uma linha */
  margin-bottom: 18px; /* Espaçamento inferior */
  text-align: left;
}

.process-field-vertical label strong {
  display: block; /* Exibe o título da label em uma linha */
  margin-bottom: 2px; /* Espaço entre título e input */
}

.process-field-vertical input {
  width: 40%; /* Largura total do input */
  padding: 5px; /* Espaçamento interno do input */
  border: 1px solid #CCC; /* Borda do input */
  border-radius: 3px; /* Arredondamento dos cantos do input */
}

.jj-input {
  position: static;
  text-align: center;
  touch-action: none;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
}

.supCus-field-vertical h2{
  color:black;
  margin-bottom: 10px;
}

.supCus-field-vertical input{
  border: none;
  border-bottom: 3px solid blue;
  width: 80%;
  color:black;
  font-weight: bold;
  text-align: center; /* Centraliza o texto dentro do input */
}


.process-field-vertical label{
  text-align: left;
  color:#000000;
}

.process-field-vertical input{
  border: none;
  border-bottom: 3px solid blue;
  width: 30%;
  appearance: textfield; /* Esconde as setas */
  color:black;
  font-weight: bold;
}

.inventory-field-vertical input {
  background-color: yellow;
  border: none;
  width: 28%; /* Defina uma largura menor, como 50% */
  appearance: textfield; /* Esconde as setas */
  color: black;
  font-weight: bold;
}

.buttonDiv{
  background-color: #ccccca;
  margin-top: 90px;
  text-align: right; /* Alinha o conteúdo dentro do contêiner à direita */
}

.button-download{
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #2F5597;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 1;
  }

  .refresh-button{
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #ff2a00;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 1;
  }

  .increaseScale{
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
    padding: 5px 10px;
    background-color: #2F5597;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 1;
  }

  .reduceScale{
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
    padding: 5px 13px;
    background-color: #ff2a00;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-family: Arial, Helvetica, sans-serif;
    z-index: 1;
  }

  .increaseScale:hover{
    background-color: #1d325b; /* Darker shade of blue on hover */
    transform: translateY(-3px); /* Add a slight upward lift on hover */
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }

  .reduceScale:hover,
  .refresh-button:hover{
    background-color: #ff0000; /* Darker shade of blue on hover */
    transform: translateY(-3px); /* Add a slight upward lift on hover */
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }

.button-download:hover{
  background-color: #1d325b; /* Darker shade of blue on hover */
  transform: translateY(-3px); /* Add a slight upward lift on hover */
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
}

.scale{
  margin-right: 10px;
  display: inline-block;
  color: #000000;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;

}

.canvas{
  display: inline-block;
  background-color: #ccccca;
  background-size: auto;
  margin-left: 0; 
  z-index: 1;
}

.header {
  margin: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0rem;
}

.grid {
  margin: 3rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  gap: 3rem 5rem;
  justify-content: center;
}

.addButton {
  border: 1px grey solid;
  background-color: #f2f2f2;
  color: white;
  padding: 0 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.addButton:hover {
  filter: invert(0.3);
  cursor: pointer;
}

.addButton h1 {
  margin: 0;
  display: inline;
  font-size: 2.5rem;
}

.addButton p {
  display: inline;
  font-size: 1.25rem;
}

a {
  margin-left: 2rem;
  color: rgb(232, 232, 232);
}

.tabContainer ul {
  /* Estilos para a lista não ordenada dentro do contêiner de abas */
  list-style-type: none; /* Remove os marcadores de lista */
  padding: -50px; /* Remove o preenchimento padrão da lista */
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 50px; /* Remove a margem padrão da lista */
  text-align: center;
  margin-bottom: 10px;
}

.tabContainer ul li {
  /* Estilos para cada item de lista dentro do contêiner de abas */
  display: inline;
  border: 0px solid #ddd;
  margin-right: 10px; /* Ajusta o espaçamento entre os itens */
  padding:  8px 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.tabContainer ul li a {
  /* Estilos para os links dentro de cada item de lista */
  text-decoration: none; /* Remover sublinhado padrão */
  padding: 10px 20px; /* Adicionar preenchimento ao redor do texto */
  display: inline-block; /* Permitir que os links se comportem como blocos para adicionar preenchimento e margem */
  color: black; /* Cor do texto */
  font-weight:700;
}

.backgroundSection {
  display: block;
  margin: auto; /* This will center the element horizontally with 20px margin on top */
  width: 1000px;
  margin-bottom: 18px;
  max-height: 100%;
  margin-top: 15px;
}

.centerContainer {
  text-align: center;
  margin-top: 18px; /* Adjust margin-top as needed */
}

@media screen and (max-width: 768px) {
  .addButton {
    padding-inline: 1rem;
  }

  .addButton p {
    display: none;
  }
}