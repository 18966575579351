.question {
  padding: 2rem;
  background-color: #f2f2f2;
  border-radius: 1.5rem;
}

.choices {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 0.75rem;
  padding-top: 0.75rem;
}

.answer {
  padding-left: 0.5rem;
}