.body {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 40% 60%;
}

.login {
  background-color: #282634;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  padding-top: 4rem;
}


.logo {
  width: 25%;
}

.title {
  font-style: italic;
}

.sidebar {
  padding: 5%;
  position: relative;
}

.sidebar_img {
  position: absolute;
  inset: 0;
  margin: auto;
  max-height: 70%;
  max-width: 70%;
  user-select: none;
  translate: 0 -15%;
}

.orgs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem
}

.logo_container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.logo_container img {
  width: 12rem;
}

@media screen and (max-width: 768px) {
  .body {
    display: block;
    background-color: #282634;
  }

  .login {
    height: auto;
    justify-content: center;
  }

  .sidebar {
    display: none;
  }
}