.progresslanding {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .progresslandingmain {
    flex: 1;
  }
  
  p {
      font-size: 1.25rem;
      font-family: Georgia, serif;
      line-height: 1.5rem;
      color: #0a0752;
      margin-top: 0;
    }
    
    h1 {
      font-size: 3rem;
      color: rgb(0,32,96);
      margin: 0;
      padding: 0;
      font-weight: bold;
      font-family: Georgia, serif;
      
  
    }
  
  .infoBackground {
    background-color: #e1eaf2;
    width: 100%;
    height: auto;
  }
    
  .progressinfo {
    background-color: #e1eaf2;
      width: 60%;
      margin: 0 auto; /* Center the container horizontally */
      padding: 20px; /* Add some padding for spacing */
      text-align: center;
      inset: 0;
      display: flex;
      flex-direction: column;
      position: relative;
  }
  .progressinfop {
    padding-top: 3%;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: rgb(54, 53, 52);
    animation: progresstransitionInInfoP 1s;
  }
  @keyframes progresstransitionInInfoP {
    from {
      opacity: 0;
      transform: translateX(-10rem);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  
  
  .info_header h1 {
      padding: 5rem 4rem 3rem 4rem;
      background-color: white;
      margin: 0;
      inset: 0;
      display: flex;
      flex-direction: column;
      position: relative;
      text-align: center;
      font-size: 4rem;
      color: rgb(8,32,123);
      /*animation: transitionInHeader 1s; */
  }
  
  @keyframes transitionInHeader {
    from {
      font-size: 2.5rem;
    }
    to {
      font-size: 4rem;
    }
  }
  
  .info_header div {
      position: absolute;
  }
  
  .transition {
    height: auto; 
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 59%;
  
  }
  
  .progresstriangle {
      width: 0; 
      height: 0; 
      border-left: 2rem solid transparent;
      border-right: 2rem solid transparent;
      margin: 0 auto;
      border-top: 1.25rem solid #e1eaf2;
      margin-bottom: 0;
      z-index: 9;
  }
  
  .progresslogo_intro {
    width: 100%;
    height: auto;
    position: absolute;
    margin: auto;
    padding-top: 1rem;
    margin-bottom: 70rem;
    margin-top: 1rem;
    translate: 0 -200%;
  }
  
  .progresslogo_intro img {
    width: 100%;
    height: auto;
    position: absolute;
    margin-top: 0;
    margin-bottom: 0;
    }
  
    .progresshero {
      height: 100vh;
      width: 100%;
      background-size: 90% auto;
      margin: auto;
      background-position-x: center;
      margin-top: 7rem;
      background-position: 50%;
      background-attachment: fixed;
    }
    
    
    .progresshero_text {
      position: relative;
      top: 75%;
      background-color: rgba(234,234,234, 0.6);
      padding: 2rem 3rem 2rem 2rem;
      max-width: 50%;
      margin: auto;
      text-align: center;
      border-radius: 2rem;
      line-height: 1.75rem;
    
    }
  
    .pictext1, .pictext2 {
      opacity: 0;
      font-size: 1.7rem;
      font-family:"'Titillium Web', sans-serif";
    }
    .pictext1active, .pictext2active  {
      opacity: 1;
      animation: transitionInPicText 1s;
      font-size: 1.7rem;
      font-family:"'Titillium Web', sans-serif";
    }
    
  
    @keyframes transitionInPicText {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    
    .logos {
      width: 100%;
      height: 2vw;
      position: relative;
      margin: auto;
      margin-top: 0.5rem;
    }
    .logos img {
      width: 50%;
      height: auto;
      position: relative;
      display: block;
      margin: auto;
    }
   
  
      .info_header::after {
        content: '';
        position: absolute;
        top: 31%;
        left: 50%;
        transform: translateX(-50%);
        width: 55px; /* Adjust the width of the rectangle */
        height: 5px; /* Adjust the height of the rectangle */
        background-color: rgb(47,85,151);
      }
  
  
  
      .partners_box {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }
      .partners_items {
        height: 10rem;
        margin: 1.25rem 5rem 5rem 5rem;
        text-align: center;
      }
      .partners_box img {
        height: 10rem
      }
  
  
  
      .footer {
        background-color: rgb(229,235,240);
        text-align: center;
        padding: 1rem;
        
        position: sticky;
        bottom: 0;
      }
      @keyframes progresstransitionInHeroText {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }



/*
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: "Space Grotesk", sans-serif;
} */
textarea {
	padding: 10px 20px;
	border: 1px solid #ddd;
	border-radius: 3px;
	margin-bottom: 15px;
}

.register,
.login,
.home,
.replies,
.modal {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 30px;
}
.registerTitle,
.loginTitle,
.homeTitle,
.repliesTitle {
	margin-bottom: 30px;
    margin-top: 30px;
    text-align: center;
}
.registerForm,
.loginForm {
	width: 80%;
	display: flex;
	flex-direction: column;
}
.registerBtn,
.loginBtn,
.homeBtn,
.modalBtn {
	display: block;
	width: 200px;
	padding: 10px;
	cursor: pointer;
	outline: none;
	border: none;
	border-radius: 3px;
	font-size: 16px;
	font-weight: bold;
	color: #fffbf5;
	background-color: #6976a0;
	margin-bottom: 15px;
}
.homeForm {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 80%;
	margin-bottom: 30px;
    margin: auto;
}
.home__container {
	display: flex;
	flex-direction: column;
	width: 75%;
}
.homeBtn {
	margin-bottom: 0px;
}
.thread__container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
}
.thread__item {
	padding: 15px;
	width: 80%;
	box-shadow: 0 0 1px 1px #c3acd0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

}
.thread_item p {
  margin-bottom: 0px;
}
.likes__container {
	display: flex;
	align-items: center;
	margin-right: 25px;
}
.likesBtn {
	height: 30px;
	color: #a99ebe;
	cursor: pointer;
}
.react__container {
	display: flex;
	align-items: center;
  padding-bottom: 0px;
}

.react__container_p {
  font-size: 1rem;
  margin: 0;
  margin-right: 1rem;
  margin-left: 1rem;
  text-align: right;
}

.react__container_time {
  font-size: 0.85rem;
  margin-left: 1rem;
}
.modal {
	background-color: #ddd;
	position: fixed;
}
.modalInput {
	width: 100%;
	padding: 15px;
	margin-bottom: 15px;
}
.modal__content {
	background-color: #fff;
	width: 70%;
	padding: 30px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.modal__content > label {
	margin-bottom: 15px;
}


















      
      @media screen and (max-width: 40rem) {
   
    
        .progresslanding p, .progresslanding li {
          font-size: 1rem
        }
  
        .progresslanding h1 {
          font-size: 1rem;
          margin: 0;
          padding: 1rem;
        }
        .info_header h1 {
          font-size: 1.5rem;
          color: rgb(0,32,96);
          text-align: center;
          /*animation: progresstransitionInHeroText 0.75s; */
          margin-bottom: 5%;
        }
        .info_header {
          margin-bottom: none;
        }
        .orgs {
          flex-direction: column;
          gap: 2.5rem;
        }
      
        .logo_container {
          flex-direction: column;
          gap: 1.5rem;
        }
        .progresshero_text {
          top: 50%;
        }
        .hero {
          margin-top: 2rem;
          height: 70vh;
        }
  
  
      }