.bar {
  position: relative;
  z-index: -1;
  top: 1.15rem;
  height: 0.3rem;
  background-color: #3A7DFF;
}

.node_container {
  display: flex;
  justify-content: flex-end;
}

.node {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  gap: 0.25rem;
}

.node p {
  font-weight: bold;
  margin-left: 2rem;
}

.circle {
  background-color: #dddddd;
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
}

.circle:hover {
  filter: invert(0.3);
  cursor: pointer;
}