p {
  font-size: 1.2rem;
  font-family: 'Titillium Web', sans-serif;
  line-height: 1.5rem;
  color: rgb(46,49,146);
}

h1 {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: 'Titillium Web', sans-serif;
  color: rgb(46,49,146);
}

/*
.knowledge_transfer_background {
  background-image: linear-gradient(to bottom, rgba(234, 234, 234, 0) 72%, rgb(234, 234, 234,1));
  height: 111.9vh;
  margin-bottom: 9.35rem;
  } */

.knowledge_transfer  {
  width: 85%;
  max-width: 520vh;
  position: relative;
  margin: auto;
  padding: 4%;
  margin-top: 3%;
  margin-bottom: 3%;
  opacity: 0;
  }

.knowledge_tran  {
  width: 85%;
  max-width: 520vh;
  position: relative;
  margin: auto;
  padding: 4%;
  margin-top: 3%;
  margin-bottom: 3%;
  opacity: 1;
  }
.knowledge_transferactive  {
    width: 85%;
    max-width: 520vh;
    position: relative;
    margin: auto;
    padding: 4%;
    margin-top: 3%;
    margin-bottom: 3%;
    opacity: 1;
    animation: knowledgeTransferIn 0.5s;

  }
@keyframes knowledgeTransferIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.knowledge_transfer img {
  width: 100%;
  height: auto;
  position: relative;
  display: block;
  }


.logo_intro {
  width: 20vw;
  max-width: 480vh;
  min-height: 10vw;
  overflow: hidden;
  position: relative;
  margin: auto;
  padding: 1rem;
  padding-top: 1.5rem;
  margin-bottom: 1.2%;

  }

.logo_intro img {
  width: 100%;
  margin: auto;
  
  height: auto;
  position: relative;
  display: block;
  animation: transitionIn 0.75s; 
  border: 0.15vw solid #1a5f90;
  }

.logoback {
    padding-top: 1%;
    background-color: white;
    margin: 0;
    inset: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
}



.infoandpic {
    background-color: rgba(123, 153, 181,0.2);
    margin: 0;
    inset: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    padding-bottom: 7%;
}

#infoandpictop {
  translate: 0 -200%;
}

.triangle {
  width: 0; 
  height: 0; 
  border-left: 4.5rem solid transparent;
  border-right: 4.5rem solid transparent;
  margin: 0 auto;
  border-top: 3rem solid white;
  margin-bottom: 0;
  z-index: 99;
  padding-bottom: 2rem;
}
.landing {
    background-color: white;
  }
/*
  .landing p {
    font-size: 1.25rem;
    font-family: Georgia, serif;
    line-height: 1.5rem;
    color: #0a0752;
  }
  
  .landing h1 {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-family: Georgia, serif;
    color: #090661;
  } */



.info {
    --b:15;
    text-align: center;
    padding-top: 1%;
    margin-top: 2rem;
    margin: auto;
    width: 70%;
    /*
    border-width:0 calc(var(--b)*1px) calc(var(--b)*1px) 0;
    background: rgb(236, 233, 233) padding-box;
    border-image:repeating-linear-gradient(-45deg,#0000 0 4px,blue 0 8px) var(--b);
   cut the corners with clip-path
  clip-path:polygon(0 0,calc(100% - calc(var(--b)*1px)) 0,calc(100% - calc(var(--b)*1px)) calc(var(--b)*1px),100% calc(var(--b)*1px),100% 100%,calc(var(--b)*1px) 100%,calc(var(--b)*1px) calc(100% - calc(var(--b)*1px)),0 calc(100% - calc(var(--b)*1px)));
  */
}

.info h1 {
    font-size: 4rem;
    color: rgb(0,32,96);
    text-align: center;
    animation: transitionInTitle 0.75s;
    margin-bottom: 5%;
  }
/*
  .info p {
    padding-top: 3%;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: rgb(85, 82, 78);

  } */
  .infop {
    padding-top: 3%;
    font-size: 1.4rem;
    line-height: 2rem;
    color: rgb(85, 82, 78);
    opacity: 0;
  }
  .infopactive {
    padding-top: 3%;
    font-size: 1.4rem;
    line-height: 2rem;
    color: rgb(85, 82, 78);
    opacity: 1;
    animation: transitionInInfoP 0.5s;
  }

  @keyframes transitionInInfoP {
    from {
      opacity: 0;
      transform: translateX(-10rem);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

.separator {
  width: 10%;
  margin: auto;
  border-bottom: 0.1rem solid #2d6ca6;
  margin-top: 1rem;
}

.vision h1 {
    padding: 2rem;
    font-size: 4rem;
    color: rgb(0,32,96);
  }

.vision {
  text-align: center;
  border-style: outset;
  border-color: #2d6ca6;
  width: 70%;
  margin: auto;
  margin-bottom: 10%;
  opacity: 0;
  margin-top: 7rem;
  padding: 1rem;
}
.visionactive {
  text-align: center;
  border-style: outset;
  border-color: #2d6ca6;
  width: 70%;
  margin: auto;
  margin-top: 7rem;
  margin-bottom: 10%;
  opacity: 1;
  animation: transitionInVisionBody 1s;
  padding: 1rem;
}

.vision p {
  font-size: 1.4rem;
    line-height: 2.2rem;
    color: rgb(85, 82, 78);
    padding: 1rem 2rem;
}

@keyframes transitionInVisionBody {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.joinP {
  translate: 0 -5rem;
  padding: 0;
}

.hero {
  height: 100vw;
  min-height: 50rem;
  width: 100%;
  background-size: 80%;
  margin: auto;
  background-position-x: center;
  background-position: 50%;
  background-attachment: fixed;
}


.hero_text {
  position: relative;
  top: 30%;
  background-color: rgba(237, 240, 242, 0.85);
  padding: 2rem 3rem 1rem 2rem;
  max-width: 50%;
  margin: auto;
  text-align: center;
  border-radius: 2rem;
  line-height: 1.75rem;
  opacity: 0;
}
.hero_textactive {
  position: relative;
  top: 30%;
  background-color: rgba(237, 240, 242, 0.85);
  padding: 2rem 3rem 1rem 2rem;
  max-width: 50%;
  margin: auto;
  text-align: center;
  border-radius: 2rem;
  line-height: 1.75rem;
  opacity: 1;
  animation: transitionInHeroText 1.5s;
}
@keyframes transitionInHeroText {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.hero_text h1 {
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: rgb(46,49,146);
}

.hero_text p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding-top: 1rem;
  padding-bottom: -2rem;
}

.contacts {
  width: 100%;
  height: 54vw;
  position: relative;
  margin: auto;
  padding-top: 1rem;
}

.contacts img {
  width: 100%;
  height: auto;
  position: absolute;
  }

.logos {
  width: 100%;
  height: 2vw;
  position: relative;
  margin: auto;
}
.logos img {
  width: 50%;
  height: auto;
  position: relative;
  display: block;
  margin: auto;
}







.infoBackground {
  background-color: #e1eaf2;
  width: 100%;
  height: auto;
}
  
.progressinfo {
  background-color: #e1eaf2;
    width: 60%;
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px; /* Add some padding for spacing */
    text-align: center;
    inset: 0;
    display: flex;
    flex-direction: column;
    position: relative;
}
.progressinfop {
  padding-top: 3%;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: rgb(54, 53, 52);
  animation: progresstransitionInInfoP 1s;
}
@keyframes progresstransitionInInfoP {
  from {
    opacity: 0;
    transform: translateX(-10rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.heroImgVision {
  width: 100%
}

.Team {
  width: 98%;
  display: flex;
  justify-content: center;
}
.TeamDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vw;
}
.TeamText {
  margin-top: 5vw;
  padding: 3vw;
  padding-top: 0;
  text-align: center;
}

.info_header h1 {
    padding: 4rem 4rem 3rem 4rem;
    background-color: rgb(141,202,218);
    margin: 0;
    inset: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    font-size: 4rem;
    animation: transitionInHeader 1s;
}

@keyframes transitionInHeader {
  from {
    font-size: 2.5rem;
  }
  to {
    font-size: 4rem;
  }
}

.info_header div {
    position: absolute;
}

.transition {
  height: auto; 
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 59%;

}

.progresstriangle {
    width: 0; 
    height: 0; 
    border-left: 2rem solid transparent;
    border-right: 2rem solid transparent;
    margin: 0 auto;
    border-top: 1.25rem solid #e1eaf2;
    margin-bottom: 0;
    z-index: 9;
}

.progresslogo_intro {
  width: 100%;
  height: auto;
  position: absolute;
  margin: auto;
  padding-top: 1rem;
  margin-bottom: 70rem;
  margin-top: 1rem;
  translate: 0 -200%;
}

.progresslogo_intro img {
  width: 100%;
  height: auto;
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
  }

  .progresshero {
    height: 100vh;
    width: 100%;
    background-size: 90% auto;
    margin: auto;
    background-position-x: center;
    margin-top: 7rem;
    background-position: 50%;
    background-attachment: fixed;
  }
  
  
  .progresshero_text {
    position: relative;
    top: 75%;
    background-color: rgba(234,234,234, 0.6);
    padding: 2rem 3rem 2rem 2rem;
    max-width: 50%;
    margin: auto;
    text-align: center;
    border-radius: 2rem;
    line-height: 1.75rem;
  
  }

  .pictext1, .pictext2 {
    opacity: 0;
    font-size: 1.7rem;
    font-family:"'Titillium Web', sans-serif";
  }
  .pictext1active, .pictext2active  {
    opacity: 1;
    animation: transitionInPicText 1s;
    font-size: 1.7rem;
    font-family:"'Titillium Web', sans-serif";
  }
  

  @keyframes transitionInPicText {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .logos {
    width: 100%;
    height: 2vw;
    position: relative;
    margin: auto;
    margin-top: 0.5rem;
  }
  .logos img {
    width: 50%;
    height: auto;
    position: relative;
    display: block;
    margin: auto;
  }
    /*
    .hero {
      position: relative;
      height: 70vh;
      overflow: hidden;
    }
    
  
    
    .hero img {
      flex: 1 1 42rem;
    }
    
    .register {
      display: block;
      background-color: black;
      color: white;
      padding: 0.75rem 2rem;
      font-size: 1rem;
      font-weight: bold;
      text-decoration: none;
    }
    
    .register:hover {
      filter: invert(0.3);
    }
    
    .benefits {
      height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin: 5vh 0;
      padding: 0 3%;
      text-align: center;
      filter: invert(1);
    }
    
    .benefits h1, .benefits li {
      padding: 1% 2%;
      margin: 1rem 0;
      background-color:rgba(255, 255, 255, 0.596);
    }
    
    .benefits div {
      text-align: left;
      font-weight: 500;
    }
    
    .benefits ul {
      list-style: none;
    }
    
    .orgs {
      background-color: white;
      padding: 5vh 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
    }
    
    .orgs h3 {
      margin-top: 0;
    }
    
    .logo_container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 10%;
    }
    
    .logo_container img {
      width: 12rem;
    }
    */
    .footer {
      background-color: #F2F2F2;
      text-align: center;
      padding: 1rem;
      margin-top: 2rem;
    }
    @keyframes progresstransitionInHeroText {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }























  .footer {
    background-color: rgb(229,235,240);
    text-align: center;
    padding: 2rem;
    margin-top: 2.5vw;
    color: rgb(85, 82, 78);
  }

  @keyframes transitionIn {
    from {
      width: 90%;
      margin: auto;
    }
    to {
      width: 100%;
    }
  }

  @keyframes transitionInTitle {
    from {
      font-size: 2.5rem;
    }
    to {
      font-size: 4rem;
    }
  }

  .progresslanding {
    margin-top: 0rem;
  }
  
  @media screen and (max-width: 40rem) {
 
  
    .landing p, .landing li {
      font-size: 1rem
    }
    .info h1 {
      font-size: 1.5rem;
      color: rgb(0,32,96);
      text-align: center;
      animation: transitionInHeroText 0.75s;
      margin-bottom: 5%;
    }
    .landing h1 {
      font-size: 1.5rem;
      margin: 0;
      padding: 0
    }
    .orgs {
      flex-direction: column;
      gap: 2.5rem;
    }
  
    .logo_container {
      flex-direction: column;
      gap: 1.5rem;
    }

    .hero {
      padding-bottom: 10rem;
      padding-top: 10rem;
    }
    .hero div {
      margin: auto;
      padding: 1.5rem;
      width: fit-content;
    }
    .hero_textactive {
      padding-bottom: 10rem;
    }
    .hero_text {
      padding-bottom: 10rem;
    }

    .progresslanding p, .progresslanding li {
      font-size: 1rem
    }

    .progresslanding h1 {
      font-size: 1rem;
      margin: 0;
      padding: 1rem;
    }
    .info_header h1 {
      font-size: 1.5rem;
      color: rgb(0,32,96);
      text-align: center;
      animation: progresstransitionInHeroText 0.75s;
      margin-bottom: 5%;
    }
    .info_header {
      margin-bottom: none;
    }
    .orgs {
      flex-direction: column;
      gap: 2.5rem;
    }
  
    .logo_container {
      flex-direction: column;
      gap: 1.5rem;
    }
    .progresshero_text {
      top: 50%;
    }
    .hero {
      margin-top: 2rem;
      height: 70vh;
    }

  }