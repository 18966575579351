.progresslanding {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.progresslandingmain {
  flex: 1;
}

p {
    font-size: 1.25rem;
    font-family: Georgia, serif;
    line-height: 1.5rem;
    color: #0a0752;
    margin-top: 0;
  }
  
  h1 {
    font-size: 3rem;
    color: rgb(0,32,96);
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-family: Georgia, serif;
    

  }



.info_header h1 {
    padding: 5rem 4rem 3rem 4rem;
    background-color: white;
    margin: 0;
    inset: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    font-size: 4rem;
    color: rgb(8,32,123);
    text-decoration: underline;
    line-height: 5px;
    margin-bottom: 5px;
    /*animation: transitionInHeader 1s; */
}


.info_header div {
    position: absolute;
}

.transition {
  height: auto; 
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 59%;

}



  @keyframes transitionInPicText {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  

/*
    .info_header::after {
      content: '';
      position:absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      width: 52px; /* Adjust the width of the rectangle 
      height: 5px; /* Adjust the height of the rectangle 
      background-color: rgb(47,85,151);
    } */



    .partners_box {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin-top: 5vw;
    }
    .partners_items {
      height: 16vw;
      margin: 0.25rem 3rem 0rem 3rem;
      text-align: center;
    }
    .partners_box img {
      height: 56%;
    }

    .footer {
      background-color: rgb(229,235,240);
      text-align: center;
      padding: 1rem;
      
      position: sticky;
      bottom: 0;
    }

    @media screen and (max-width: 40rem) {
 
  
      .progresslanding p, .progresslanding li {
        font-size: 1.5rem
      }

      .progresslanding h1 {
        font-size: 1.5rem;
        margin: 0;
        padding: 1rem;
      }
      .info_header h1 {
        font-size: 2.5rem;
        color: rgb(0,32,96);
        text-align: center;
        /*animation: progresstransitionInHeroText 0.75s; */
        margin-bottom: 5%;
        margin-top: 4%;
      }
      .info_header {
        margin-bottom: none;
      }
      .orgs {
        flex-direction: column;
        gap: 2.5rem;
      }
    
      .logo_container {
        flex-direction: column;
        gap: 1.5rem;
      }
      .progresshero_text {
        top: 50%;
      }
      .hero {
        margin-top: 2rem;
        height: 70vh;
      }
      /*
      .info_header::after {
        content: '';
        position: absolute;
        top: 23%;
        left: 50%;
        transform: translateX(-50%);
        width: 52px; /* Adjust the width of the rectangle 
        height: 5px; /* Adjust the height of the rectangle 
        background-color: rgb(47,85,151);
      } 
      */

      .partners_box img {
        height: 85%;
      }
      .partners_items {
        height: 16vw;
        margin: 1.25rem 3rem 1rem 3rem;
        text-align: center;
      }
    }