.icon {
  width: 2rem;
}

.response {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: left;
  margin-left: 2rem;
}

.dim {
  display: flex;
  align-items: center;
  gap: 0.5rem
}