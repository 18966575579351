.bar {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(123, 153, 181,0.2);
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 3rem;
}

.bar2 {
  height: 9vh;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  padding-left: 0;
  position: absolute;
  width: 80%;
}

.bar1 {
  height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(123, 153, 181,0.2);
  padding-left: 0;
}

.logo {
  display: block;
  height: 90%;
  max-width: 100%;
  height: 70px;
  position: relative;
  display: flex;
  /*width: fit-content;*/
}

.logo img {
  max-width: 100%;
  max-height: 100%;
}

.bar nav {
  display: flex;
  gap: 0.1rem;
}


.link {
  text-decoration: none;
  color: black;
  font-weight: 500;
  display: block;
  height: 100%;
  margin-right: 0px;
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  color: #002060;
  text-transform: uppercase;
  padding-top: 21px;
  padding-bottom: 18px;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.link1 {
  text-decoration: none;
  color: black;
  font-weight: 500;
  height: 100%; 
  font-size: 1rem;
  color: #002060;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px; 
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  text-align: center;
  padding-left: 0;
  margin-left: 0;
}

.noPadding {
  padding: 0;
}

.navH {
  position: relative;
  display: flex;
  top: 12vh;
  left: 0;
  width: 100%;
  height: 19rem;
  background: #d2dde6;
  z-index: 100;
  justify-content: center;
  text-align: center;
}

.navH nav {
  display: flex;
  flex-direction: column;
  text-align:center;
}

.link:after,
.link:before {
  content: "";
  position: absolute;
  width: 0%;
  display: block;
  height: 2px;
  transition: all 0.3s ease;
  margin-top: 2px;
  transition: all 200ms ease-in;
}

.link:after {
  bottom: 25%;
  left: 0;
}

.link:before {
  top: 25%;
  right: 0;
}

.link:hover::after {
  width: 100%;
  height: 2px;
  bottom: 25%;
  
  background-color: #002060;
}
.link:hover::before {
  width: 100%;
  height: 2px;
  top: 25%;
  background-color: #002060;
}

.mobileMenuIcon {
  display: flex;
  justify-content: flex-end;
}

.menuIcon {
  font-size: 2rem;
  padding-right: 1rem;
}