.page {
  padding: 2rem 5%;
  zoom: 0.85
}

.page button {
  border: none;
  background-color: black;
  color: white;
  padding: 0.3rem 1.75rem;
  border-radius: 0.75rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.page button:hover {
  filter: invert(0.3);
}

.page button:disabled {
  filter: none;
  opacity: 0.2;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 0.5rem;
}

.question_nav {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-inline: 2rem;
}

.question_nav button:last-child {
  margin-left: auto;
}

.summary {
  margin-top: 1rem;
  background-color: #f2f2f2;
  border-radius: 2rem;
  padding: 2rem 4rem;
  text-align: center;
}
