.site {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: 2rem;
  padding: 1.5rem 2rem;
  box-shadow: 0 0 0.1rem;
  text-align: center;
}

.title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.5rem;
}

.details {
  text-align: left;
  font-style: italic;
}

.link {
  color: black;
  text-decoration: none;
  font-weight: 550;
  font-size: 1.25rem;
}

.link:hover {
  filter: invert(0.3);
}

.id {
  text-align: left;
  margin-top: -1.25rem;
  font-weight: 600;
}
