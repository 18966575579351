.header {
  margin: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0rem;
}

.grid {
  margin: 3rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  gap: 3rem 5rem;
  justify-content: center;
}

.addButton {
  border: 1px grey solid;
  background-color: #f2f2f2;
  color: white;
  padding: 0 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.addButton:hover {
  filter: invert(0.3);
  cursor: pointer;
}

.addButton h1 {
  margin: 0;
  display: inline;
  font-size: 2.5rem;
}

.addButton p {
  display: inline;
  font-size: 1.25rem;
}

a {
  margin-left: 2rem;
  color: rgb(232, 232, 232);
}

.tabContainer ul {
  /* Estilos para a lista não ordenada dentro do contêiner de abas */
  list-style-type: none; /* Remove os marcadores de lista */
  padding: -50px; /* Remove o preenchimento padrão da lista */
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 50px; /* Remove a margem padrão da lista */
  text-align: center;
  margin-bottom: 10px;
}

.tabContainer ul li {
  /* Estilos para cada item de lista dentro do contêiner de abas */
  display: inline;
  border: 0px solid #ddd;
  margin-right: 10px; /* Ajusta o espaçamento entre os itens */
  padding:  8px 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.tabContainer ul li a {
  /* Estilos para os links dentro de cada item de lista */
  text-decoration: none; /* Remover sublinhado padrão */
  padding: 10px 20px; /* Adicionar preenchimento ao redor do texto */
  display: inline-block; /* Permitir que os links se comportem como blocos para adicionar preenchimento e margem */
  color: black; /* Cor do texto */
  font-weight:700;
}

.backgroundSection {
  display: block;
  margin: auto; /* This will center the element horizontally with 20px margin on top */
  width: 1000px;
  margin-bottom: 18px;
  max-height: 100%;
  margin-top: 15px;
}

.centerContainer {
  text-align: center;
  margin-top: 18px; /* Adjust margin-top as needed */
}

@media screen and (max-width: 768px) {
  .addButton {
    padding-inline: 1rem;
  }

  .addButton p {
    display: none;
  }
}