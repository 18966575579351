.results {
  margin: 3% 5%;
}

.results h1, h2 {
  margin: 0;
}

.title {
  text-align: center;
  margin-bottom: 1rem;
}

.title span {
  font-size: 1.25rem;
  font-style: italic;
}

.sections {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  flex-direction: column-reverse;
}

.pains, .overall {
  background-color: #e5ebf0;
  padding: 0.5rem 1rem;
}

.overall {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overall span {
  font-weight: bold;
  font-size: 1.5rem;
}

.pains {
  margin-top: 1rem;
}

.list li::marker {
  font-weight: bold;
}