.page {
  text-align: center;
}

.form {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  gap: 1.5rem;
}

.submit {
  border: none;
  background-color: black;
  color: white;
  width: fit-content;
  padding: 0.5rem 3rem;
  border-radius: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  align-self: center;
}

.submit:hover {
  filter: invert(0.3);
  cursor: pointer;
}

.label select, .label input {
  width: fit-content;
  max-width: 70%;
}

.label span {
  margin-right: 1.25rem;
  font-style: italic;
  font-weight: 500;
}

.location {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 1rem;
  max-width: 80%;
}

.select {
  width: 100%;
  padding: 8px;
  padding-right: 1.5rem;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #f9f9f9;
  color: #333;
}

@media screen and (max-width: 60rem) {
  .form {
    gap: 1rem;
  }

  .location {
    flex-direction: column;
    gap: 1rem;
  }
}