.pie {
  --p:20;      /* the percentage */
  --c:#04A722; /* the color */
  --w:300px;   /* the size*/
  
  width:var(--w);
  aspect-ratio:1/1;
  position:absolute;
  margin: auto;
  inset: 0;
}
.pie:before,
.pie:after {
  content:"";
  position:absolute;
  border-radius:50%;
}
.pie:before {
  inset:0;
  background:
    radial-gradient(farthest-side, var(--c) 98%, #0000) no-repeat,
    conic-gradient(var(--c) calc(var(--p)*1%), #0000 0);
  background-size: 0 0, auto;
}
