.header {
  margin: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0rem;
}

.grid {
  margin: 3rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  gap: 3rem 5rem;
  justify-content: center;
}

.addButton {
  border: 1px grey solid;
  background-color: #f2f2f2;
  color: white;
  padding: 0 1.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.addButton:hover {
  filter: invert(0.3);
  cursor: pointer;
}

.addButton h1 {
  margin: 0;
  display: inline;
  font-size: 2.5rem;
}

.addButton p {
  display: inline;
  font-size: 1.25rem;
}

a {
  margin-left: 2rem;
  color: rgb(232, 232, 232);
}

@media screen and (max-width: 768px) {
  .addButton {
    padding-inline: 1rem;
  }

  .addButton p {
    display: none;
  }
}